import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import SelectPaymentOption from "components/organisms/SelectPaymentOption";
import SelectRefundOption from "components/organisms/SelectRefundOption";
import { number, required } from "utils/validate.util";

export { default } from "./RefundForm";


export const fields = (payment_method: string) => {

  const fields: ReduxFormField[] = [
    {
      name: "payment_method",
      label: "Select Payment Method",
      validate: [required],
      component: SelectPaymentOption,
      cellProps: { md: 12 },
    },

    {
      name: "amount",
      label: "Amount",
      validate: [required, number],
      component: InputRedux,
      cellProps: { md: 12 },
    },
    {
      name: "payment_reference",
      label: "Payment Reference",
      component: InputRedux,
      cellProps: { md: 12 },
      InputProps: { multiline: true, rows: 3 },
    },
  ];
  if (payment_method === "Pay-fort") 
    fields.splice(1, 0, {
      name: "payment_intent",
      label: "Paid Amount",
      validate: [required],
      component: SelectRefundOption,
      cellProps: { md: 12 },
    });


  return fields;
}
