import { PaymentLinkState } from ".";
import { SelectOption } from "components/atoms/Select";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { config } from "config";

const default_page_size = config.PAGE_SIZE;

const initialState: PaymentLinkState = {
  paymentLinks: [],
  paymentLink: null,
  loading: true,
  paymentLinkOptions: [],
  refresh: 0,
  refreshLoader: false,
  count: 0,
  current_filters: {},
  filters: { page: 1, page_size: default_page_size },
};

export const paymentLinkSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {
    clear: (state) => {
      state.paymentLinkOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    setPage: (state, action: PayloadAction<number>) => {
      const  page  = action.payload;
      state.filters.page = page;
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },

    addPaymentLink: (state, action) => {
      state.paymentLinks.unshift(action.payload);
    },
    updatePaymentLink: (state, action) => {
      const { id, paymentLink } = action.payload;
      state.paymentLinks.every(({ _id }, i) => {
        if (id === _id) {
          state.paymentLinks[i] = paymentLink;
          return false;
        }
        return true;
      });
    },
    setPaymentLink: (state, action) => {
      state.paymentLink = action.payload;
      if (action.payload == null) return
    },
    setPaymentLinks: (state, action) => {
      const { paymentLinks, count } = action.payload;
      let options: SelectOption[] = [];
      paymentLinks.forEach(({ _id, company_name }: any) => {
        options.push({ value: _id, label: company_name });
      });
      state.paymentLinkOptions = options;
      state.refreshLoader = false;
      state.paymentLinks = paymentLinks;
      state.count = count;
    },
  },
});

const paymentLinkReducer = paymentLinkSlice.reducer;

export const paymentLinkActions = paymentLinkSlice.actions;
export default paymentLinkReducer;
