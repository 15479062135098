import { fields } from ".";
import Button from "components/atoms/Button";
import { modalActions } from "redux/slices/modal";
import { reduxForm } from "redux-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ReduxFormFields from "components/molecules/ReduxFormFields";

function RefundForm({ form, handleSubmit }: any) {
  const dispatch = useAppDispatch();
  const payment_method=useAppSelector((state)=>state.form?.['RefundForm']?.values?.payment_method)

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ReduxFormFields fields={fields(payment_method)} />
        <br />

        <Button
          variant="outlined"
          sx={{ marginRight: "10px" }}
          onClick={() => dispatch(modalActions.closeModal())}
        >
          Cancel
        </Button>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </form>
    </div>
  );
}

export default reduxForm({ form: "RefundForm" })(RefundForm);
