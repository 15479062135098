import { useAppDispatch, useAppSelector } from "redux/hooks";
import BookingService from "services/booking.service";
import RefundForm from "./RefundForm";
import { MODAL, modalActions } from "redux/slices/modal";
import SubscriptionService from "services/subscription.service";
import { useEffect } from "react";
import { change } from "redux-form";
import ToasterService from "utils/toaster.util";
import { digitFixer } from "utils/digitfixer.util";

export default function Refund() {
  let form = "RefundForm";
  const dispatch = useAppDispatch();
  // const _id = useAppSelector((state) => state.booking.booking?._id);
  const booking = useAppSelector((state) => state.booking.booking);
  const bookingRefundOptions = useAppSelector((state) => state.booking.bookingRefundOptions);
  const data = useAppSelector((state) => state.modal.data);
  const payment_method = useAppSelector((state) => state.form?.[form]?.values?.payment_method);
  const payment_reference = useAppSelector((state) => state.form?.[form]?.values?.payment_reference);
  const payment_intent = useAppSelector((state) => state.form?.[form]?.values?.payment_intent);

  useEffect(() => {
    // if (payment_method === "Pay-fort" && booking?.added_by === "User")
    //   dispatch(change(form, "payment_reference", `Pay-fort# ${data}`));
    // else if (payment_reference && payment_reference.includes("Pay-fort")) {
    //   dispatch(change(form, "payment_reference", ``));
    // }
    if (payment_intent) {
      const priceInfo = bookingRefundOptions.find((item) => item.value === payment_intent);
      let price=digitFixer(priceInfo?.data?.price-priceInfo?.data?.refundedPrice)
      dispatch(change(form, "amount", price || 0));
      dispatch(change(form, "payment_reference", `Pay-fort# ${priceInfo.value}`));

    }



  }, [payment_method, payment_intent])

  const handleSubmit = async (values: any) => {
    let newValues = { ...values };
    newValues.amount = Number(newValues.amount);

    let data = { ...newValues };
    if (data.payment_intent) {
      const priceInfo = bookingRefundOptions.find((item) => item.value === payment_intent);
      let price=digitFixer(priceInfo?.data?.price-priceInfo?.data?.refundedPrice)
      if (data.amount > price) {
        ToasterService.showError("Price can not be greater then paid price!");
        return
      }
      data.refundedInfo = {
        trip: priceInfo.data.trip,
        price: priceInfo.data.price,
        payment_intent: priceInfo.value,
        merchant_ref: priceInfo.data.trip === "extension" ? priceInfo.data?.merchant_ref : null,
        extend_id: priceInfo.data.trip === "extension" ? priceInfo.data?.extend_id : null,
        paidBy: priceInfo.data.trip === "extension" ? priceInfo.data?.paidBy : null
      };
      delete data.payment_intent;
    }
    dispatch(
      modalActions.openModal({
        width: "500px",
        type: MODAL.CONFIRMATION_FORM,
        data: {
          heading: "Refund Amount",
          message: `Do you really want to refund this amount?`,
        },
      })
    );

    SubscriptionService.subscribe(() => {
      BookingService.refundBooking(booking?._id, data);
    });
  };

  return (
    <div>
      <h3>Refund Booking Payment</h3>
      <RefundForm onSubmit={handleSubmit} />
    </div>
  );
}
