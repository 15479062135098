// export { default } from "./AddRolesPermissionsForm";
export interface Permissions {
  name: String;
  read: Boolean | null;
  write: Boolean | null;
  delete: Boolean | null;
  readRoutes: any[];
  writeRoutes: any[];
  deleteRoutes: any[];
}

export const permissionCheckList = [
  {
    name: "dashboard",
    read: false,
    write: null,
    delete: null,
    readRoutes: ['/dashboard'],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "trip",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/bookings', '/booking-details/:id', '/events', '/event-details/:id', '/booking-ratings'],
    writeRoutes: ['/add-booking', '/update-booking/:id'],
    deleteRoutes: []
  },
  {
    name: "user",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/users', '/user-details/:id', '/user-points/:id'],
    writeRoutes: ['/add-user'],
    deleteRoutes: []
  },
  {
    name: "driver",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/chauffeurs', '/chauffeur-details/:id',],
    writeRoutes: ['/add-chauffeur', '/update-chauffeur/:id'],
    deleteRoutes: []
  },
  {
    name: "vehicle",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/vehicles', '/vehicle-details/:id'],
    writeRoutes: ['/add-vehicle', '/update-vehicle/:id'],
    deleteRoutes: []
  },
  {
    name: "model",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/models',],
    writeRoutes: ['/add-model', '/update-model/:id'],
    deleteRoutes: []
  },
  {
    name: "class",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/class',],
    writeRoutes: ['/add-class', '/update-class/:id'],
    deleteRoutes: []
  },
  {
    name: "availability",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/availability',],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "zone",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/Zone',],
    writeRoutes: ['/add-zone', '/update-zone/:id'],
    deleteRoutes: []
  },
  {
    name: "partner",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/partners', '/partner-details/:id'],
    writeRoutes: ['/add-partner', '/update-partner/:id'],
    deleteRoutes: []
  },
  {
    name: "partner_requests",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/partner-requests',],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "company",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/companies', '/company-details/:id'],
    writeRoutes: ['/add-company', '/update-company/:id', '/add-company-user'],
    deleteRoutes: []
  },
  {
    name: "company_requests",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/company-requests'],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "campaign",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/campaigns', '/campaign-details/:id'],
    writeRoutes: ['/add-campaign', '/update-campaign/:id'],
    deleteRoutes: []
  },
  {
    name: "price",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/pricing'],
    writeRoutes: ['/add-pricing', '/update-pricing/:id'],
    deleteRoutes: []
  },
  {
    name: "invoice",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/partner-invoices', '/company-invoices', '/customer-invoices'],
    writeRoutes: ['/add-invoices'],
    deleteRoutes: []
  },
  {
    name: "contact",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/contacts'],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "request_history",
    read: false,
    write: null,
    delete: null,
    readRoutes: ['/search-history'],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "report",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/reported-problems'],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "dynamics",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/dynamics'],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "role",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/roles', '/update-role/:id'],
    writeRoutes: ['/add-role', '/add-role-permission', '/update-role-permission/:id'],
    deleteRoutes: []
  },
  {
    name: "car_movement",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/car-movement' ],
    writeRoutes: ['/add-car-movement', '/update-car-movement/:id'],
    deleteRoutes: []
  },
  {
    name: "commission",
    read: false,
    write: false,
    delete: false,
    readRoutes: ['/commission' ],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "fuel",
    read: false,
    write: null,
    delete: null,
    readRoutes: ['/fuels' ],
    writeRoutes: [],
    deleteRoutes: []
  },
  {
    name: "payment_link",
    read: false,
    write: false,
    delete: null,
    readRoutes: ['/payment-link' ],
    writeRoutes: ['/add-payment-link'],
    deleteRoutes: []
  },

];

export interface IRoute {
  path: string;
  name: string;
}

