import { config } from "config";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BookingLoadingPayload,
  BookingState,
  BookingType,
  IBookings,
  NewBookingPayload,
  RemoveBookingPayload,
  SetBookingsPayload,
  SetFiltersPayload,
  SetPagePayload,
  UpdateBookingPayload,
} from ".";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialBooking: IBookings = {
  count: 0,
  bookings: [],
  loading: true,
  current_filters: {},
  filters: { page: 1, page_size: default_page_size },
};
const initialState: BookingState = {
  tab: 0,
  refresh: 0,
  booking: null,
  loading: true,
  bookingRatings: [],
  refreshLoader: false,
  refund: {},
  bookingOptions: [],
  bookingRefundOptions: [],
  bookingCounts: {},
  all: initialBooking,
  admin: initialBooking,
  market: initialBooking,
  review: initialBooking,
  planned: initialBooking,
  completed: initialBooking,
  cancelled: initialBooking,
  driverRating: initialBooking,
  failed: initialBooking,
  calendar: initialBooking,
  search: initialBooking
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setRefund: (state, action) => {
      state.refund = action.payload;
    },
    setPage: (state, action: PayloadAction<SetPagePayload>) => {
      const { type, page } = action.payload;
      state[type].filters.page = page;
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state, action: PayloadAction<BookingType>) => {
      const type = action.payload;
      state[type].filters.page = 1;
    },
    setFilters: (state, action: PayloadAction<SetFiltersPayload>) => {
      const { type, data } = action.payload;
      state[type].filters = data;
      state.refreshLoader = true;
    },
    resetFilters: (state, action: PayloadAction<BookingType>) => {
      const type = action.payload;
      state.refresh += 1;
      state.refreshLoader = true;
      state[type].filters = initialBooking.filters;
      state[type].current_filters = initialBooking.current_filters;
    },
    setCurrentFilters: (state, action: PayloadAction<SetFiltersPayload>) => {
      const { type, data } = action.payload;
      state[type].current_filters = data;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoading: (state, action: PayloadAction<BookingLoadingPayload>) => {
      const { type, loading } = action.payload;
      state[type].loading = loading;
      state.refreshLoader = loading
    },
    setBookingCountTabs: (state, action: PayloadAction<any[]>) => {
      state.bookingCounts = action.payload;
    },
    newBooking: (state, action: PayloadAction<NewBookingPayload>) => {
      const { type, booking } = action.payload;
      state[type].count += 1;
      state[type].bookings.unshift(booking);
    },
    updateBooking: (state, action: PayloadAction<UpdateBookingPayload>) => {
      const { id, type, booking } = action.payload;
      state[type].bookings.every(({ _id }, i) => {
        if (id === _id) {
          state[type].bookings[i] = booking;
          return false;
        }
        return true;
      });
    },
    removeBooking: (state, action: PayloadAction<RemoveBookingPayload>) => {
      const { id, type } = action.payload;
      state[type].bookings.every(({ _id }, i) => {
        if (id === _id) {
          state[type].count -= 1;
          state[type].bookings.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setBooking: (state, action) => {
      state.booking = action.payload;
    },
    updateBookingDetails: (state, action) => {
      const booking = action.payload;
      if (booking?._id === state.booking?._id) state.booking = booking;
    },
    setBookings: (state, action: PayloadAction<SetBookingsPayload>) => {
      const { type, count, bookings } = action.payload;

      state.refreshLoader = false;

      state[type].count = count;
      state[type].bookings = bookings;
    },
    setBookingsRatings: (state, action) => {
      const bookingRatings = action.payload || [];
      state.bookingRatings = bookingRatings?.filter?.(
        (bookingRating: any) => bookingRating?.ratings
      );
    },
    sendMarket: (state, action: PayloadAction<UpdateBookingPayload>) => {
      const { id, type, booking } = action.payload;

      state[type].bookings.every(({ _id }, i) => {
        if (id === _id) {
          state[type].count -= 1;
          state["market"].count += 1;
          state[type].bookings.splice(i, 1);
          state["market"].bookings.unshift(booking);
          return false;
        }
        return true;
      });
    },
    acceptMarket: (state, action: PayloadAction<UpdateBookingPayload>) => {
      const { id, type, booking } = action.payload;
      state[type].bookings.every(({ _id }, i) => {
        if (id === _id) {
          state[type].count -= 1;
          state["planned"].count += 1;
          state[type].bookings.splice(i, 1);
          state["planned"].bookings.unshift(booking);
          return false;
        }
        return true;
      });
    },
    setSearchBookings: (state, action: PayloadAction<SetBookingsPayload>) => {
      const { count, bookings } = action.payload;
      state["search"].count = count;
      state.refreshLoader = false;

      let options: SelectOption[] = [];
      bookings.forEach(({ _id, booking_number }: any) =>
        options.push({ value: _id, label: "Booking# " + booking_number })
      );
      state['search'].bookings = bookings;
      state.bookingOptions = options;
    },
    setRefundOptions: (state, action: PayloadAction<any[]>) => {
      state.bookingRefundOptions = action.payload;
    },
  },
});

const bookingReducer = bookingSlice.reducer;

export const bookingActions = bookingSlice.actions;
export default bookingReducer;
