import http from "./http.service";
import Promisable from "./promisable.service";
import { zoneActions } from "redux/slices/zone";
// import { cityActions } from "redux/slices/city";
import { NavigateFunction } from "react-router-dom";
import { loaderActions } from "redux/slices/loader";
import { getAppDispatch } from "utils/dispatch.util";
import { formLoaderActions } from "redux/slices/formLoader";
import { AppDispatch } from "redux/store";
import { modalActions } from "redux/slices/modal";
import PricingService from "./pricing.service";

const url = "/zone";

const ZoneService = {
	addZone: async (data: any, navigate?: NavigateFunction) => {
		const dispatch = getAppDispatch();
		dispatch?.(formLoaderActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.post(`${url}`, data)
		);

		if (success) {
			const { zone } = success.data.data;
			dispatch?.(zoneActions.addZone(zone));
			navigate?.("/zone");
		}

		dispatch?.(formLoaderActions.setLoading(false));
		return [success, error];
	},
	updateZone: async (
		id: string,
		data: any,
		navigate?: NavigateFunction
	) => {
		const dispatch = getAppDispatch();
		dispatch?.(formLoaderActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.patch(`${url}/${id}`, data)
		);

		if (success) {
			const { zone } = success.data.data;
			dispatch?.(zoneActions.updateZone({ id, zone }));
			navigate?.("/zone");
		}

		dispatch?.(formLoaderActions.setLoading(false));
		return [success, error];
	},
	getZone: async (id: string) => {
		const dispatch = getAppDispatch();
		dispatch?.(loaderActions.setLoading(true));
		dispatch?.(zoneActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.get(`${url}/${id}`)
		);

		if (success) {
			const { zone } = success.data.data;
			dispatch?.(zoneActions.setZone(zone));
		} else dispatch?.(zoneActions.setZone({ data: "Not Found" }));

		dispatch?.(loaderActions.setLoading(false));
		dispatch?.(zoneActions.setLoading(false));
		return [success, error];
	},
	getZonesCoordinates: async () => {
		const dispatch = getAppDispatch();
		dispatch?.(loaderActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.get(`${url}/zones-coordinates`)
		);

		if (success) {
			const { zone } = success.data.data;;
			dispatch?.(zoneActions.setZone(zone));

		} else dispatch?.(zoneActions.setZone({ data: "Not Found" }));

		dispatch?.(loaderActions.setLoading(false));
		return [success, error];
	},
	getAllZones: async () => {
		const dispatch = getAppDispatch();
		dispatch?.(zoneActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.get(`${url}`)
		);

		if (success) {
			const { zones } = success.data.data;
			dispatch?.(zoneActions.setZones(zones));
		}

		dispatch?.(zoneActions.setLoading(false));
		return [success, error];
	},

	addZoneDiscount: async (data: any,params:string, dispatch?: AppDispatch) => {
		dispatch?.(modalActions.setLoading(true));

		http.setJWT();

		const [success, error]: any = await Promisable.asPromise(
			http.post(`${url}/individual-discount`, data)
		);

		if (success) {
			PricingService.getAllPricings(params)
			dispatch?.(modalActions.closeModal())
		}

		dispatch?.(modalActions.setLoading(false));
		return [success, error];
	},
};

export default ZoneService;
