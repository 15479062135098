import UpdatePayRoll from "pages/PayRoll/UpdatePayRoll";
import { lazy } from "react";
import { MODAL } from "redux/slices/modal";

import AddAttachment from "./AddAttachment";
import EditMapAddress from "./EditMapAddress";
import UpdateDynamics from "./UpdateDynamics";
import UpdatePaymentStatus from "./UpdatePaymentStatus";
import Refund from "./Refund/Refund";
import ConfirmRefund from "./ConfirmRefund/ConfirmRefund";
import BookingDetails from "./BookingDetails";
import AddShift from "./AddShift";

const AddNotes = lazy(() => import("./AddNotes"));
const DeleteUser = lazy(() => import("./DeleteUser"));
const AddPayment = lazy(() => import("./AddPayment"));
const PayInvoice = lazy(() => import("./PayInvoice"));
const HandleStatus = lazy(() => import("./HandleStatus"));
const DisableModal = lazy(() => import("./DisableModal"));
const ChildrenSeats = lazy(() => import("./ChildrenSeats"));
const AssignBooking = lazy(() => import("./AssignBooking"));
const CancelBooking = lazy(() => import("./CancelBooking"));
const UploadDocument = lazy(() => import("./UploadDocument"));
const ConfirmationForm = lazy(() => import("./ConfirmationForm"));
const ConfirmationModal = lazy(() => import("./ConfirmationModal"));
const ExportTableOptions = lazy(() => import("./ExportTableOptions"));
const UpdateBookingStatus = lazy(() => import("./UpdateBookingStatus"));
const MarketTransferPopup = lazy(() => import("./MarketTransferPopup"));
const UpdateBookingBillingAddress = lazy(() => import("./UpdateBookingBillingAddress"));
const SearchHistoryStats = lazy(() => import("./SearchHistoryStats"));
const AssignPrice = lazy(() => import("./AssignPrice"));
const UpdateCountryPrice = lazy(() => import("./UpdateCountryPrices"));
const DynamicCarMovement = lazy(() => import("./DynamicCarMovement"));
const CarMovementFinish = lazy(() => import("./CarMovementFinish"));
const AddIndividualDiscount = lazy(() => import("./AddIndividualDiscount"));


export { default } from "./AppModal";

export type ModalMapper = {
  [key in MODAL]: "" | JSX.Element;
};

export const modalMapper: ModalMapper = {
  ADD_NOTES: <AddNotes />,
  PAY_INVOICE: <PayInvoice />,
  DELETE_USER: <DeleteUser />,
  ADD_PAYMENT: <AddPayment />,
  HANDLE_STATUS: <HandleStatus />,
  DISABLE_DRIVER: <DisableModal />,
  CHILDREN_SEATS: <ChildrenSeats />,
  ASSIGN_BOOKING: <AssignBooking />,
  CANCEL_BOOKING: <CancelBooking />,
  UPLOAD_DOCUMENT: <UploadDocument />,
  CONFIRMATION_FORM: <ConfirmationForm />,
  MARKET_TRANSFER: <MarketTransferPopup />,
  CONFIRMATION_MODAL: <ConfirmationModal />,
  EXPORT_TABLE_OPTIONS: <ExportTableOptions />,
  UPDATE_BOOKING_STATUS: <UpdateBookingStatus />,
  UPDATE_BOOKING_BILLING_ADDRESS: <UpdateBookingBillingAddress />,
  UPDATE_PAYROLL: <UpdatePayRoll />,
  UPDATE_DYNAMICS: <UpdateDynamics />,
  UPDATE_PAYMENT_STATUS: <UpdatePaymentStatus />,
  ADD_ATTACHMENT: <AddAttachment />,
  EDIT_ADDRESS: <EditMapAddress />,
  REFUND: <Refund />,
  CONFIRM_REFUND: <ConfirmRefund />,
  SEARCH_HISTORY_STATS: <SearchHistoryStats />,
  ASSIGN_PRICE: <AssignPrice />,
  UPDATE_CROSS_COUNTRY_PRICE:<UpdateCountryPrice/>,
  BOOKING_DETAILS:<BookingDetails/>,
  Add_SHIFT:<AddShift/>,
  DYNAMIC_CAR_MOVEMENT:<DynamicCarMovement/>,
  FINISH_CAR_MOVEMENT:<CarMovementFinish/>,
  INDIVIDUAL_DISCOUNT:<AddIndividualDiscount/>,

};
